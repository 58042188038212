import React from "react";
import { graphql } from "gatsby";
import { get } from "lodash";

import queryString from "query-string";

import GeneralSupport from "../../content/support/general";

const GeneralSupportPage = ({ data }) => {
	const faqs = convertNodes(get(data, "allContentfulPage"));
	const videos = convertNodes(get(data, "allContentfulHelpVideo")).map(
		video => ({
			...video,
			video: {
				id: queryString.parse(video.videoUrl.split("?")[1]).v,
				title: video.title
			}
		})
	);

	const generalFaqTitles = ["bulk bidding"];

	const generalFaqs = faqs.filter(f =>
		generalFaqTitles.includes(f.title.trim().toLowerCase())
	);

	const generalVideoTitles = [
		"bulk bidding",
		"sliding competitive bid close times",
		"proxy bidding"
	];
	const generalVideos = videos.filter(v =>
		generalVideoTitles.includes(v.title.trim().toLowerCase())
	);

	return <GeneralSupport {...{ videos: generalVideos, faqs: generalFaqs }} />;
};

function convertNodes(all) {
	const nodes = get(all, "edges");
	if (!nodes) return [];
	return nodes.map(n => n.node);
}

export default GeneralSupportPage;

export const pageQuery = graphql`
	query GeneralSupportQuery {
		allContentfulPage(
			filter: { pageType: { eq: "FAQ" } }
			sort: { fields: [title], order: ASC }
		) {
			edges {
				node {
					slug
					title
				}
			}
		}

		allContentfulHelpVideo {
			edges {
				node {
					id
					title
					videoUrl
				}
			}
		}
	}
`;
